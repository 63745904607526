import { Component, OnInit } from '@angular/core';
import { BodyMeasures } from '../../models/bodymeasures';
import { BikeMeasures } from '../../models/bikemeasures';
import { BikeFit } from '../../models/bikefit';
import Victor from 'victor';
import { Line } from '../../models/line';
import { LegendEntry } from '../../models/legendentry';
import { Path } from '../../models/path';
import { Circle } from '../../models/circle';
import { SEAT_HEIGHT_FACTOR } from '../../models/constants';



@Component({
  selector: 'app-fitter',
  templateUrl: './fitter.component.html',
  styleUrls: ['./fitter.component.scss']
})
export class FitterComponent implements OnInit {

  bodyMeasures: BodyMeasures;
  bikeMeasures: BikeMeasures;
  bikeFit: BikeFit;
  svgWidth: number = 640;
  svgHeight: number = 480;
  pixelPerUnitMM: number;
  coordinateOrigin: Victor;
  wheelRadiusMM: number = 311;
  show = false;
  // http://www.arsmartialis.com/index.html?name=http://www.arsmartialis.com/faq/m_anteil.html
  percentOfWeightLegs: number = 0.38;
  percentOfWeightTorsoArmsHead: number = 0.62;

  private lines: Line[] = new Array<Line>();
  private legend: LegendEntry[] = new Array<LegendEntry>();
  private paths: Path[] = new Array<Path>();
  private circles: Circle[] = new Array<Circle>();
  private saddle: Path = {
    style: "fill:#000000;fill-opacity:1;stroke:#000000;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1",
    d: "M 0.29399603,7.6272813 C 4.570308,2.1482583 30.228184,-0.79170667 47.867972,0.94554533 65.340527,2.6663283 103.3542,1.4141273 113.88354,1.7473523 c 7.02725,0.222394 19.77795,1.069079 14.43256,4.543582 C 122.97071,9.7654373 91.292339,13.492821 78.203059,12.3045 52.166468,9.9407463 41.127442,16.834377 25.952509,22.641846 16.156057,26.390962 15.197709,21.404618 5.773023,15.244464 -1.389504,10.5629 0.29399603,7.6272813 0.29399603,7.6272813 Z",
    transform: "translate(0,0)",
    placementVector: new Victor(-40,-3)
  }
  private wheel: Circle = {
    // style: 'stroke:tomato;stroke-width:2; fill:none',
    cx: 50,
    cy: 50,
    r: 45,
    transform: 'translate(0,0)'
  }

  constructor() { }

  ngOnInit() {
    this.bodyMeasures = {
      innerLegLength: 860,
      trunkLength: 620,
      armLength: 620
    };

    this.bikeMeasures = {
      seatAngle: 73.5,
      stackLength: 587,
      reachLength: 423,
      stemHeight: 70,
      stemLength: 90
    }
  }

  evaluate() {
    console.log("evaluation started");
    this.show = true;

    // reset
    this.lines = new Array<Line>();
    this.legend = new Array<LegendEntry>();
    this.paths = new Array<Path>();
    this.circles = new Array<Circle>();

    
    this.bikeFit = {
      seatHeight: SEAT_HEIGHT_FACTOR * this.bodyMeasures.innerLegLength
    }

    const seatVector = this.unitVectorFromAngle(180 - this.bikeMeasures.seatAngle).multiplyScalar(this.bikeFit.seatHeight);
    const stackVector = new Victor(0, this.bikeMeasures.stackLength);
    const reachVector = new Victor(this.bikeMeasures.reachLength, 0);
    const stemHeightVector = new Victor(0, this.bikeMeasures.stemHeight);
    const stemLengthVector = new Victor(this.bikeMeasures.stemLength, 0);
    const fromSeatToHandles = stackVector.clone().add(reachVector).add(stemHeightVector).add(stemLengthVector).subtract(seatVector);

    // calculation of trunk position
    const arcuscos = Math.acos( ((Math.pow(this.bodyMeasures.trunkLength, 2) + Math.pow(fromSeatToHandles.length(), 2) - Math.pow(this.bodyMeasures.armLength, 2)) / (2 * this.bodyMeasures.trunkLength * fromSeatToHandles.length())) );
    const angleTrunkToSeatToHandles = arcuscos * 180 / Math.PI;
    const trunkAngle = angleTrunkToSeatToHandles + fromSeatToHandles.angleDeg();

    const trunkVector = new Victor(Math.cos(trunkAngle * Math.PI / 180), Math.sin(trunkAngle * Math.PI / 180));
    trunkVector.multiplyScalar(this.bodyMeasures.trunkLength);

    const armsVector = stackVector.clone().add(reachVector).add(stemHeightVector).add(stemLengthVector).subtract(seatVector.clone().add(trunkVector));

    // adjust scaling factors to height/width of vectors
    const totalHeightMM = this.wheelRadiusMM + seatVector.y + trunkVector.y;
    this.pixelPerUnitMM = this.svgHeight / totalHeightMM;

    const widthmm = this.svgWidth / this.pixelPerUnitMM;
    const heigthmm = this.svgHeight / this.pixelPerUnitMM;

    this.coordinateOrigin = new Victor(widthmm / 2.0, heigthmm - this.wheelRadiusMM);
    // end scaling factors

    this.drawVectorFromOrigin(seatVector, { borderColor: 'rgba(255,0,0,1)', text: 'Sitz und Sitzrohr'});
    this.drawVectorFromOrigin(stackVector, { text: 'Stack', borderColor: 'rgba(33,179,8,1)'});
    this.drawVectorFromAtoB(stackVector, stackVector.clone().add(reachVector), { text: 'Reach', borderColor: 'rgba(255, 125, 0, 1)'});
    this.drawVectorFromAtoB(stackVector.clone().add(reachVector), stackVector.clone().add(reachVector).add(stemHeightVector), { text: 'Vorbauhöhe', borderColor: 'rgba(0,66,255,1)'});
    this.drawVectorFromAtoB(stackVector.clone().add(reachVector).add(stemHeightVector), stackVector.clone().add(reachVector).add(stemHeightVector).add(stemLengthVector), { text: 'Vorbaulänge', borderColor: 'rgba(0,66,255,1)'});
    this.drawVectorFromAtoB(seatVector, seatVector.clone().add(trunkVector), { text: 'Oberkörper', borderColor: 'rgba(6,255,0,1)'});
    this.drawVectorFromAtoB(seatVector.clone().add(trunkVector), stackVector.clone().add(reachVector).add(stemHeightVector).add(stemLengthVector), { text: 'Arme', borderColor: 'rgba(255,0,228,1)'});

    const inverseTrunkVector = trunkVector.clone().multiplyScalar(-1);
    this.bikeFit.angleTrunkAndArms = Math.acos(inverseTrunkVector.dot(armsVector) / (Math.sqrt(inverseTrunkVector.dot(inverseTrunkVector) * armsVector.dot(armsVector)))) * 180 / Math.PI;

    const xAxis = new Victor(1,0);
    this.bikeFit.angleTrunkAndXAxis = Math.acos(trunkVector.dot(xAxis) / (Math.sqrt(trunkVector.dot(trunkVector) * xAxis.dot(xAxis)))) * 180 / Math.PI;

    this.bikeFit.drop = seatVector.y - (stackVector.y + stemHeightVector.y);

    // TODO: refactor the following
    this.paths.push(this.saddle);
    this.setSaddle(seatVector);
    
    const wheelRadius = this.wheelRadiusMM * this.pixelPerUnitMM;
    this.wheel.cx = this.toPixelCoordinates(reachVector.clone().multiplyScalar(1.5)).x;
    this.wheel.cy = this.toPixelCoordinates(reachVector).y;
    this.wheel.r = wheelRadius;
    this.circles.push(this.wheel);
    var backWheel: Circle = {
        cx: this.wheel.cx,
        cy: this.wheel.cy,
        r: wheelRadius,
        transform: 'translate(0,0)'
    }
    backWheel.cx = this.toPixelCoordinates(reachVector.clone().multiplyScalar(-1)).x;
    backWheel.cy = this.toPixelCoordinates(reachVector.clone().multiplyScalar(-1)).y;
    this.circles.push(backWheel);
  }

  unitVectorFromAngle(angle: number) : Victor {
    const unitVector = new Victor(Math.cos(angle * Math.PI / 180), Math.sin(angle * Math.PI / 180));
    return unitVector;
  }

  setSaddle(vec: Victor) {
    const totalVec = this.saddle.placementVector.clone().add(this.coordinateOrigin.clone().multiplyScalar(this.pixelPerUnitMM));

    totalVec.addScalarX(this.toPixelVector(vec).x);
    totalVec.addScalarY( - this.toPixelVector(vec).y);
    this.saddle.transform = 'translate(' + totalVec.x + ',' + totalVec.y +')scale(0.7)';
  }

  toPixelCoordinates(vec: Victor) : Victor {
    const origin = this.toPixelVector(this.coordinateOrigin);
    origin.addScalarX(this.toPixelVector(vec).x);
    origin.subtractScalarY(this.toPixelVector(vec).y);
    return origin;
  }

  toPixelVector(vec: Victor) : Victor {
    const pixelVector = vec.clone().multiplyScalar(this.pixelPerUnitMM);
    return pixelVector;
  }

  drawVectorFromOrigin(vec: Victor, legendEntry?: LegendEntry) {
    const startVec = this.coordinateOrigin.clone();
    startVec.x = startVec.x * this.pixelPerUnitMM;
    startVec.y = startVec.y * this.pixelPerUnitMM;

    const endVec = startVec.clone();
    endVec.x += vec.x * this.pixelPerUnitMM;
    endVec.y -= vec.y * this.pixelPerUnitMM;
    const line: Line = {
      borderColor: 'rgba(125, 125, 32, 0.5)',
      width: 5,
      startVec: startVec,
      endVec: endVec
    }

    if (legendEntry) {
      line.borderColor = legendEntry.borderColor;
      this.legend.push(legendEntry);
    }

    this.lines.push(line);
  }

  drawVectorFromAtoB(from: Victor, to: Victor, legendEntry?: LegendEntry) {
    const startVec = this.coordinateOrigin.clone();
    startVec.x = (startVec.x + from.x) * this.pixelPerUnitMM;
    startVec.y = (startVec.y - from.y) * this.pixelPerUnitMM;

    const endVec = this.coordinateOrigin.clone();
    endVec.x = (endVec.x + to.x) * this.pixelPerUnitMM;
    endVec.y = (endVec.y - to.y) * this.pixelPerUnitMM;

    const line: Line = {
      borderColor: 'rgba(125, 125, 32, 0.5)',
      width: 5,
      startVec: startVec,
      endVec: endVec
    }

    if (legendEntry) {
      line.borderColor = legendEntry.borderColor;
      this.legend.push(legendEntry);
    }

    this.lines.push(line);
  }

}
